import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './css/login.css';

import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';

import  { updatePassword,sendPasswordResetEmail } from "firebase/auth";
import { useAuthContext } from './authContext';
import { auth } from '../../firebaseAuth';

export default function Change(props) {
  
  const { auth } = useAuthContext();
  const [step, setStep] = useState(0);

  const handleClick=(num)=>{
    setStep(num)
    // const submitPasswordResetEmail = async () => {
    // await auth
    //     .sendEmailVerification(email)
    //     .then((resp) => {
    //     // メール送信成功
    //     })
    //     .catch((error) => {
    //     // メール送信失敗
    //     console.log(error)
    //     })
    // }
  }


  const [mail,setMail]=useState("");
  const [mailRules,setMailRules]=useState("");
  const [sendMail,setSendMail]=useState(false);

  const hndMail=(x)=>{
    const regex = /.+@.+\..+/

    if(x.target.value === ""){
      setMailRules("メールアドレスを入力してください。")
    }else if(!regex.test(x.target.value)){
      setMailRules("メールアドレスの形式が違います。")
    }
    else if(regex.test(x.target.value)){
      setMailRules("")
      setMail(x.target.value)
    }
  }



  // 認証パスワードの部分　↓
  //　認証コード　input 参照元　↓
  // https://ramble.impl.co.jp/2007/

  const [code, setCode] = useState(["", "", "", "", "", ""])
  const [inputIndex, setIndex] = useState(0)
  const navigate = useNavigate();

  const inputRef = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  const bsFunction = (event) => {
    if (event.key === "Backspace" && inputIndex > 0 && step === 1) {
      inputRef[inputIndex - 1].current.focus()
      setIndex(inputIndex - 1)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", bsFunction, false)
    return () => {
      document.removeEventListener("keydown", bsFunction, false)
    }
  }, [bsFunction])


  
  // 認証パスワードの部分　↑



  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [new_password,setNewPassWord]=useState("");
  const [new_passwordRules,setNewPassWordRules]=useState("");
  const [new_password2,setNewPassWord2]=useState("");
  const [new_passwordRules2,setNewPassWordRules2]=useState("");
  
  const [pass_umu,setPassUmu]=useState();
  


  const hndPassWord1=(x)=>{
    if(x.target.value === ""){
      setNewPassWordRules("パスワードを入力してください。")
    }else{
      setNewPassWordRules("")
      setNewPassWord(x.target.value)
    }
  }

  const hndPassWord2=(x)=>{
    if(x.target.value === ""){
      setNewPassWordRules2("パスワードを入力してください。")
    }else{
      setNewPassWordRules2("")
      setNewPassWord2(x.target.value)
    }
  }

  const hndPassWordSetting=()=>{
    if(new_password === new_password2){
      setPassUmu(false)
    }else if(new_password !== new_password2){
      setPassUmu(true)
    }
  }
  const sendPassResetMail = () => {
    fetch('https://api-d.homestation.jp/agentsMailCheck', {
    // fetch('http://localhost:8080/agentsMailCheck', {
            method: 'post',
            body: JSON.stringify({mail:mail}),
            headers: { 'Content-type': 'application/json; charset=UTF-8' },
        }).then(async (res)=>{
            
            const data = await res.json();
            if(data){
                sendPasswordResetEmail(auth, mail)
                .then(() => {
                    alert("メールを送信しました。")
                    setSendMail(true)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(error,"エラー！")
                });
            }else{
                alert('登録がありません')
            }
        }).catch((error)=>{
            console.log(error)
            alert('エラーが発生しました。')
        })
  }

  return (
  <main>
    <div className='login'>
      <div className='login_inner'>

        <div className='login_top'>
          <img className='logo' src={`${process.env.PUBLIC_URL}/img/global_tree.png`} alt='global_tree'/>
          <p className='site_title'>賃貸仲介業者様専用サイト</p>
        </div>

        {step === 0?
          <div className='login_body'>
            <p>メールアドレスを認証してください</p>
            <div className='inputbox'>
              <TextField className='textbox' placeholder="メールアドレス" variant="outlined" 
              onChange={hndMail} error={mailRules} helperText={mailRules}/>
            </div>
            
            <div className='btnBox'>

              {sendMail ? 
              <div className='loginBtn_disable'>
              パスワード再設定メール送信済み
            </div> :
              <div className='loginBtn_b' onClick={() => sendPassResetMail(1)}>
                パスワード再設定メールを送信する
              </div>}
            </div>
          </div>
          :(step === 1?
          <div className='login_body'>
            <p>認証コードを入力してください</p>

            <div className="flex items-center justify-around">
              {[...Array(6)].map((_, i) => (
                <input
                  className="ninshoInput"
                  maxLength={1}
                  key={i}
                  autoFocus={i === 0}
                  value={code[i]}
                  type="tel"
                  ref={inputRef[i]}
                  onChange={(e) => {
                    const codeArray = [
                      i !== 0 ? code[0] : e.target.value,
                      i !== 1 ? code[1] : e.target.value,
                      i !== 2 ? code[2] : e.target.value,
                      i !== 3 ? code[3] : e.target.value,
                      i !== 4 ? code[4] : e.target.value,
                      i !== 5 ? code[5] : e.target.value,
                    ]
                    setCode([...codeArray])
                    if (e.target.value !== "") {
                      i < 5 && inputRef[i + 1].current.focus()
                      setIndex(inputIndex + 1)
                    }
                  }}
                />
              ))}
            </div>
            
            <div className='btnBox' style={{marginTop:"40px"}}>
              <div className='loginBtn_b' onClick={() => handleClick(2)}>
                続行
              </div>
            </div>
          </div>
          :(step === 2?
            <div className='login_body'>
              <p>パスワードを変更する</p>
              <div className='inputbox'>
                <FormControl sx={{width: '100%' }} variant="outlined">
                  <OutlinedInput placeholder="パスワード"
                    id="outlined-adornment-password" type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={hndPassWord1} error={new_passwordRules}
                  />
                  {new_passwordRules?<FormHelperText error>{new_passwordRules}</FormHelperText>:""}
                </FormControl>
              </div>

              <p style={{marginTop:"20px"}}>確認の為もう一度入力ください</p>
              <div className='inputbox'>
                <FormControl sx={{width: '100%' }} variant="outlined">
                  <OutlinedInput placeholder="パスワード"
                    id="outlined-adornment-password" type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={hndPassWord2} error={new_passwordRules2}
                  />
                  {new_passwordRules2?<FormHelperText error>{new_passwordRules2}</FormHelperText>:""}
                </FormControl>
              </div>

              {pass_umu?<p className='error'>パスワードが一致していません。</p>:""}
              
              <div className='btnBox'>
                <div className='loginBtn_b' onClick={hndPassWordSetting}>
                  設定する
                </div>
              </div>
            </div> 
          :""))
        }

       
        
    
      </div>

    </div>

  </main>

  );
}



