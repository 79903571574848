import React from 'react';
import moment from 'moment';
// import { useEffect, useState } from 'react';


export default function Tatemono(props) {
  // var open = props.open;
  const [detail,setDetail] = React.useState([]) 
  React.useEffect(()=>{
    
    if(props.bukken){
        let Detail = props.bukkens.filter(x => x.Tatemono_Id == props.bukken.Tatemono_Id)
        setDetail(Detail)
    }
  },[props.bukken])

    return (
        <>
        {props.bukken ?Object.keys(props.bukken).length === 0  ?
        '':
    <div className="side-property-area is-open">
        <div className="btn-close" onClick={()=>props.setbukken({})}>
          <img src="/img/icon/icon_close.png" alt='閉じる'/>
        </div> 
        <div  className="side-property">
          <div align="center" className="side-property-img">
            <img src={"https://img.global-center.co.jp/v2/tatemono/"+props.bukken.Tatemono_Id+"/1/1/185/278/1"} alt='外観写真'/>
          </div> 
          <div className="side-property-container">
            <p className="container-ttl">
                {props.bukken.Tatemono_Name}
            </p> 
            <p className="container-txt">
              <span>所在地</span>{props.bukken.Shikuchoson + props.bukken.Choiki}
            </p> 
            <p className="container-txt">
            <span>築年</span>{moment(props.bukken.Shunkou_Date).format('YYYY年M月')}
            </p>
          </div>
        </div> 

        <div className="list-property-scroll">
          <ul className="list-property">
          {detail.length > 0 ?
            detail.map(room => 
                <li className="list-property__item">
                <a target="_blank" href={'/detail/'+ room.Tatemono_Id +'/'+ room.Room_Id}>
                  <div className="madori">
                    <img src={"https://img.global-center.co.jp/v2/rooms/"+room.Tatemono_Id+"/"+room.Room_Id+"/8/1/80/80/0"} alt='間取り'/>
                  </div>
                  <div className="room-data">
                    <p style={{color: "rgb(237, 50, 50)", marginBottom:"5px"}}>
                      <span className="num-price">{room.ChinRyou/10000}</span><span className="txt-fee">万円</span>
                      <span className="txt-fee">［共益費 {room.KyouekiHi ? room.KyouekiHi.toLocaleString() + '円': ''}］</span>
                    </p> 
                    <p style={{marginBottom:"5px"}}>
                      <span className="shiki-icon txt-num">{room.ShikiKin ? room.ShikiKin/10000 + '万円' : '-'}</span> 
                      <span className="rei-icon txt-num">{room.ReiKin ? room.ReiKin + '万円' : '-'}</span>
                    </p> 
                    <p style={{marginBottom: "5px"}}>
                        {room.Room_Name}/{room.Madori_Name}［{room.Senyuu_Menseki}㎡］
                    </p>
                    
                    <img className='arrow' src={`${process.env.PUBLIC_URL}/img/icon/arrow.png`} alt='矢印'/>
                  </div>

                </a>
              </li>     
                
                )
                :''
          }
          </ul>
        </div>
    </div>
    :
    ''
    }
    </>
    )
}