import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
// import logo from './logo.svg';
import './css/choumeiTable.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };


const ChoumeiTable = forwardRef((props, ref) => {

  const [choumei, setChoumei] = useState([]);
  const [choumei1, setChoumei1] = useState();
  const [choumei2, setChoumei2] = useState();
  const [choumei3, setChoumei3] = useState();
  const [choumei4, setChoumei4] = useState();
  const [choumei5, setChoumei5] = useState();
  const [choumei6, setChoumei6] = useState();
  const [choumei7, setChoumei7] = useState();
  const [choumei8, setChoumei8] = useState();
  const choumei9 =[
    {id:'池戸',name:"池戸"},
    {id:'鹿伏',name:"鹿伏"}, 
    {id:'下高岡',name:"下高岡"}, 
    {id:'氷上',name:"氷上"}, 
    {id:'平木',name:"平木"}, 
    {id:'三木町その他',name:"三木町その他"},
    {id:'さぬき市',name:"さぬき市"},
    {id:'東かがわ市',name:"東かがわ市"},
    {id:'宇多津町',name:"宇多津町"},
    {id:'綾川町',name:"綾川町"}
  ];

  

  const [choumei10, setChoumei10] = useState(); //choumei7,choumei8ごっちゃにしてる配列

  const [area, setArea] = useState([]);

  useEffect(() => {
    const getChoumei = async () => {
      const response = await fetch(
        'https://api-d.homestation.jp/chomei/getChomei'
      );
      const data = await response.json();
      setChoumei(JSON.parse(data));
      setChoumei1(JSON.parse(data).filter(value => value.chiiki === 1));
      setChoumei2(JSON.parse(data).filter(value => value.chiiki === 2));
      setChoumei3(JSON.parse(data).filter(value => value.chiiki === 3));
      setChoumei4(JSON.parse(data).filter(value => value.chiiki === 4));
      setChoumei5(JSON.parse(data).filter(value => value.chiiki === 5));
      setChoumei6(JSON.parse(data).filter(value => value.chiiki === 6));
      setChoumei7(JSON.parse(data).filter(value => value.chiiki === 7));
      setChoumei8(JSON.parse(data).filter(value => value.chiiki === 8));

      const mergedArray = JSON.parse(data).filter(value => value.chiiki === 7).concat(JSON.parse(data).filter(value => value.chiiki === 8));
      setChoumei10(mergedArray.concat(choumei9))

    };

    getChoumei();
    
  }, []);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);

  const clearArea = ()=>{
    setArea([])
  }
  useImperativeHandle(ref, () => ({
    clearArea,
  }));
  const hndChoumeiArea=(x)=>{
    let value =  x.target.value;
    
    // チェックが外された場合
    if(area.includes(value)){
      setArea(area.filter((v) => v !== value));

      if(value === 'areaset_1'){
        let Any = choumei1.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_2'){
        let Any = choumei2.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_3'){
        let Any = choumei3.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_4'){
        let Any = choumei4.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_5'){
        let Any = choumei5.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_6'){
        let Any = choumei6.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_7'){
        let Any = choumei7.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_8'){
        let Any = choumei8.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_9'){
        let Any = choumei9.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
      else if(value === 'areaset_10'){
        let Any = choumei10.map(item => item.name)
        props.selectChoumei(props.Choumei.filter(element => !Any.includes(element)));
      }
    }
    // チェックされた場合  
    else{
      setArea([...area,value]);

      if(value === 'areaset_1'){
        let Any = choumei1.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_2'){
        let Any = choumei2.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_3'){
        let Any = choumei3.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_4'){
        let Any = choumei4.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_5'){
        let Any = choumei5.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_6'){
        let Any = choumei6.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_7'){
        let Any = choumei7.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_8'){
        let Any = choumei8.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_9'){
        let Any = choumei9.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }
      else if(value === 'areaset_10'){
        let Any = choumei10.map(item => item.name);
        props.selectChoumei([...props.Choumei, ...Any]);
      }

    }
      
  }


  const hndChoumei=(x)=>{
    let value =  x.target.value;
    if (props.Choumei.includes(value)) {
    // チェックが外された場合
      props.selectChoumei(
        props.Choumei.filter((v) => v !== value)
      );
    // チェックされた場合    
    } else {
      props.selectChoumei([...props.Choumei, value]);
    }
  }

  // console.log(choumei10)

  return (
    <div className='Choumei'>
      <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen1(!open1)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_1'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_1')}
              />
              <span className='aTitle'>高松市中心部</span>
            </label>
            <span className={ open1 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open1 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei1?choumei1.map((item,i) => {
                return (
                  <li className='list' key={'chomei1_'+i}>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div>

      <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen2(!open2)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_2'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_2')}
              />
              <span className='aTitle'>レインボー・サンフラワー通り付近</span>
            </label>
            <span className={ open2 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open2 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei2?choumei2.map((item,i) => {
                return (
                  <li className='list' key={'chomei2_'+i}>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div>

      <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen3(!open3)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_3'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_3')}
              />
              <span className='aTitle'>高松市南部</span>
            </label>
            <span className={ open3 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open3 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei3?choumei3.map((item,i) => {
                return (
                  <li className='list' key={'chomei3_'+i}>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div>

      <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen4(!open4)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_4'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_4')}
              />
              <span className='aTitle'>高松市西部</span>
            </label>
            <span className={ open4 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open4 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei4?choumei4.map((item,i) => {
                return (
                  <li className='list' key={'chomei4_'+i}>
                    <label className='clist'>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div>

      <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen5(!open5)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_5'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_5')}
              />
              <span className='aTitle'>高松市東部</span>
            </label>
            <span className={ open5 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open5 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei5?choumei5.map((item,i) => {
                return (
                  <li className='list' key={'chomei5_'+i}>
                    <label className='clist'>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div>

      {/* <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen6(!open6)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_6'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_6')}
              />
              <span className='aTitle'>国分寺</span>
            </label>
            <span className={ open6 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open6 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei6?choumei6.map((item) => {
                return (
                  <li className='list'>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div> */}

      {/* <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen7(!open7)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_7'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_7')}
              />
              <span className='aTitle'>香川町・香南町・塩江町</span>
            </label>
            <span className={ open7 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open7 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei7?choumei7.map((item) => {
                return (
                  <li className='list'>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div> */}

      {/* <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen8(!open8)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_8'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_8')}
              />
              <span className='aTitle'>庵治町・牟礼町</span>
            </label>
            <span className={ open8 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open8 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei8?choumei8.map((item) => {
                return (
                  <li className='list'>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div> */}

      {/* 香川町・香南町・塩江町・庵治町・牟礼町がもどったら戻す */}
      {/* <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen9(!open9)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_9'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_9')}
              />
              <span className='aTitle'>その他</span>
            </label>
            <span className={ open9 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open9 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei9?choumei9.map((item) => {
                return (
                  <li className='list'>
                    <label className='clist' key={item.id}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.id)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div> */}

      <div className='chomeiTable'>
        <div className="accordionBtn" onClick={() => setOpen9(!open9)}>
          <p>
            <label className='clist'>
              <input
                className="checkbox"
                type="checkbox"
                value={'areaset_10'}
                onChange={hndChoumeiArea}
                checked={area.includes('areaset_10')}
              />
              <span className='aTitle'>その他</span>
            </label>
            <span className={ open9 ? 'acIcon is-open':'acIcon'}>
              <img src={`${process.env.PUBLIC_URL}/img/icon/ico-ac-close.png`} alt="icon"/>
            </span>
          </p>
        </div>
        <div className={ open9 ? 'chomeiList is-open':'chomeiList'}>
          <ul className='search_list flex madori' style={{lineHeight:1.5,marginBottom:0}}>
            {choumei10?choumei10.map((item,i) => {
                return (
                  <li className='list' key={'chomei10_'+i}>
                    <label className='clist'>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.name}
                        onChange={hndChoumei}
                        checked={props.Choumei.includes(item.name)}
                      />
                      <span className="checktext">{item.name}</span>
                    </label>
                  </li>
                )
                })
              :""}
          </ul>
        </div>
      </div>

    </div>
  );
})

export default ChoumeiTable