import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../../firebaseAuth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState('');
  const [load, setLoad] = useState(false);

  const value = {
    user,
    load,
    auth
  };

  useEffect(() => {
    const unsubscribed = onAuthStateChanged(auth,(user) => {
        setUser(user);
        setLoad(true)
    });
    return () => {
      unsubscribed();
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}