// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import  { getAuth } from "firebase/auth";
import "firebase/compat/storage";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
const firebaseConfig = {
    
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  };

const app = firebase.initializeApp(firebaseConfig);

// Firebase認証の取得
export const auth = getAuth(app);

// export const user = firebase.auth().currentUser;
// export const uth = firebase.auth();
//  default auth
