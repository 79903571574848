import React from 'react';
// import { Route, Routes } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
// import { useRouter } from 'next/router'
import { useEffect, useState, useRef  } from 'react';
import './map.css';
import Tatemono from'./Tatemono.js';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import { useSearchContext } from '../../App';

// import Slide from './swiper';
// import Map from './Map';

import { useAuthContext } from '../Login/authContext';

// import logo from './logo.svg';
import './map.css';

import SubjectIcon from '@mui/icons-material/Subject';
import PlaceIcon from '@mui/icons-material/Place';
import CancelIcon from '@mui/icons-material/Cancel';


// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };



export default function Map(props) {
    const mapRef = useRef(null);
    const navigate = useNavigate();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        language:"ja"
    })
    const [map, setMap] = React.useState(null)
    const [zoom, setZoom] = React.useState(12)
    const [first, setFirst] = React.useState(true)
    const [markers,setMarkers] = useState([]);
    const [select,setSelect] = useState({});
    const [conditions,setConditions] = useState({});
    const [posts, setPosts] = useState([]);
    const  searchdata = useSearchContext();
    const [defaultLatLng,setdefaultLatLng] = useState({lat: parseFloat(34.318172),lng:parseFloat(134.054613)});
    const containerStyle={
        width: "100%",
        height: "100%"
    };
    const { user, load } = useAuthContext();
    const ChangeBounds = ()=>{
        const map = mapRef.current;
        // const bounds = map.getBounds();
        // console.log('北東',bounds.getSouthWest().toUrlValue())
        // console.log('南西',bounds.getNorthEast().toUrlValue())
      if(first){
        map.setZoom(14);
        setFirst(false)
      }
    }
    const onLoad = React.useCallback(function callback(map) {
      mapRef.current = map;
      
      const bounds = new window.google.maps.LatLngBounds({lat: parseFloat(34.318172),lng:parseFloat(134.054613)});
      map.fitBounds(bounds);
      map.setZoom(14);
      
      setMap(map)
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])
  
  
    useEffect(() => {
        if(load && !user){
            navigate('/login')
        }
      }, [load]);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPosts = async () => {
      try{
        setIsLoading(true);
      const response = await fetch(
        'https://api-d.homestation.jp/searchRooms/getList'
      );
      const data = await response.json();
      setPosts(JSON.parse(data));
      }finally{
        setIsLoading(false);
      }
    };
    console.log(searchdata)
    if(posts.length == 0 && searchdata && Object.keys(searchdata).indexOf('search') != -1 &&  Object.keys(searchdata.search).length == 0){
        getPosts();
    }else{
        const getPosts = async () => {
            try{
                setIsLoading(true);
                const response = await fetch('https://api-d.homestation.jp/searchRooms/searchList', {
                // const response = await fetch('http://localhost:8080/searchRooms/searchList', {
                    method: 'post',
                    body: JSON.stringify(searchdata.search),
                    headers: { 'Content-type': 'application/json; charset=UTF-8' },
                })
                
                const data = await response.json();
                setPosts(JSON.parse(data));
            }finally{
                setIsLoading(false);
            }
          
        };
         
        if(searchdata && Object.keys(searchdata).indexOf('search') != -1 &&  Object.keys(searchdata.search).length > 0){
            getPosts();
        }
    }
    setZoom(20)
  }, []);

  useEffect(() => {
    const getPosts = async () => {
        try{
            setIsLoading(true);
            const response = await fetch('https://api-d.homestation.jp/searchRooms/searchList', {
            // const response = await fetch('http://localhost:8080/searchRooms/searchList', {
                method: 'post',
                body: JSON.stringify(searchdata.search),
                headers: { 'Content-type': 'application/json; charset=UTF-8' },
            })
            
            const data = await response.json();
            setPosts(JSON.parse(data));
        }finally{
            setIsLoading(false);
        }
      
    };
     
    if(searchdata && Object.keys(searchdata).indexOf('search') != -1 &&  Object.keys(searchdata.search).length > 0){
        getPosts();
    }
  }, [searchdata]);

  return user && isLoaded ? (
  <main>
    <div id='MAP'>
      <div className='map_head'>
        {/* 一旦コメントアウト */}
        <div className='BtnBox'> 
          <div className='btns blue'>
            <span className='icon'>
              <PlaceIcon/>
            </span>
            <span className='text'>地図表示</span>
          </div>
          <a className='btns' style={{cursor: 'pointer'}} onClick={()=>navigate('/list')}>
            <span className='icon'>
              <SubjectIcon/>
            </span>
            <span className='text'>一覧表示</span>
          </a>
        </div>
        <div className='total'>
          検索結果 <span className='num' style={{fontFamily:"Roboto,sans-serif"}}>{posts.length}</span><span className='ken'>件</span>
        </div>
      </div>

      {/* <Tatemono setOpen={setOpen} open={open}/> */}
      <Tatemono bukken={select} setbukken={setSelect} bukkens={posts}/>
      

      <div className='MapArea'>
          <GoogleMap 
            ref={ mapRef }
            zoom={zoom}
            onBoundsChanged={ChangeBounds}
            mapContainerStyle={containerStyle}
            // onClick={handleMapClick}
            onLoad={onLoad}
            // onUnmount={onUnmount}
          >
            
            {posts ? 
                posts.map((mark,i) => (
                    mark && mark.Ido && mark.Keido ? 
                <Marker
                    key={'marker'+i}
                    position={{lat:parseFloat(mark.Ido),lng:parseFloat(mark.Keido)}}
                    onClick={()=>setSelect(mark)}
                    icon={{
                        url: select && select.Tatemono_Id == mark.Tatemono_Id ? "/img/mapicon/icon_now.png" : "/img/mapicon/icon-ie.png",
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(16.5, 16.5),
                        scaledSize: new window.google.maps.Size(33, 50)
                    }}
                ></Marker>
                :""
                ))
            :""}
          </GoogleMap>

      </div>

    </div>
  </main>

  ) : (<></>);
}