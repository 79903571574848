import React,{ useEffect, useState, Dispatch, SetStateAction, useRef, MutableRefObject } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import ChomeiTable from './ChomeiTable';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useParams, useNavigate, Outlet,useLocation } from 'react-router-dom';
import { auth } from '../../firebaseAuth';
import { useAuthContext } from '../Login/authContext';
// import logo from './logo.svg';

import './css/side.css';

type List = {
  id: number;
  value: string;
  text: boolean;
};
type SearchProps = {
    searchVal: Dispatch<SetStateAction<object>>;
};
const Side :React.FC<SearchProps> = (props) => {
  const chomeiRef = useRef<any>(null);
  let location = useLocation();
  const navigate = useNavigate();
  const { user, load } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [agent, setAgent] = React.useState('');
  const theme = createTheme({
    palette: {
      primary: {
        main:'#4ab7f4',
        dark:'#70c6ed',
        //　↑:hoverしたときの色
      },
    },
  });
  // const [campaign, setCampaign] = useState({
  //   c1:false,
  // });
  React.useEffect(() => {
    if(user){
        const getPosts = async () => {
            const response = await fetch(
              'https://api-d.homestation.jp/agents/'+user.uid
            );
            const a = await response.json();
            let data = JSON.parse(a)
            setAgent(data[0].name);
        
          };
        getPosts();
    }
  }, [load]);
  
  const handleClick = (event:any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const campaignList = [
    {id:1, value:"1", text:"初期費用得々キャンペーン(社会人のみ)"},
    {id:2, value:"2", text:"ゼロ９(社会人のみ)"},
    {id:3, value:"3", text:"新生活応援割(学生のみ)"}
  ];
  const kouzouList = [
    {id:1, value:"3", text:"鉄筋コンクリート"},
    {id:2, value:"1,12", text:"木造"},
    {id:3, value:"2,9", text:"鉄骨/軽量鉄骨"},
    {id:4, value:"14", text:"重量鉄骨"}
  ];

  const madoriList = [
    {id:1, value:"1", text:"1R"},
    {id:2, value:"2", text:"1K"},
    {id:3, value:"3", text:"1DK"},
    {id:4, value:"4", text:"1LDK"},
    {id:5, value:"5", text:"2K"},
    {id:6, value:"6", text:"2DK"},
    {id:7, value:"7", text:"2LDK"},
    {id:8, value:"8", text:"3K"},
    {id:9, value:"9", text:"3DK"},
    {id:10, value:"10", text:"3LDK"},
    {id:11, value:"11", text:"4K以上"},
  ];

  const setsubiList = [
    {id:1, value:"20", text:"バス・トイレ別"},
    {id:2, value:"153", text:"2階以上"},
    {id:3, value:"parking", text:"駐車場あり"},
    {id:4, value:"ac", text:"エアコン付き"},
    {id:5, value:"69", text:"室内洗濯機置場"},
    {id:6, value:"77", text:"独立洗面台"},
    {id:7, value:"minami", text:"南向き"},
    {id:8, value:"37", text:"オートロック"},
    {id:9, value:"3", text:"追い焚き機能"},
    {id:10, value:"2k", text:"コンロ2口以上"},
    {id:11, value:"45", text:"ペット相談可(小型犬)"},
    {id:12, value:"41", text:"TVモニターフォン"},
    {id:13, value:"1", text:"温水洗浄便座"},
    {id:14, value:"toshigas", text:"都市ガス"},
    {id:15, value:"kgkdtuki", text:"家具家電付き"},
  ];

  const [campaign, setCampaign] = useState<string[]>([]);
  const [chinryouKyou, setChinryouKyou] = useState<boolean>(false);
  const [chinryou_from, setChinryouFrom] = useState(0);
  const [chinryou_to, setChinryouTo] = useState(0);
  const [chikunen, setChikunen] = useState(0);
  const [kouzou, setKouzou] = useState<string[]>([]);
  const [menseki_u, setMensekiU] = useState(0);
  const [menseki_t, setMensekiT] = useState(0);
  const [madori, setMadori] = useState<string[]>([]);
  const [setsubi, setSetsubi] = useState<string[]>([]);
  const [choumei, setChoumei] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(()=>{
    setOpen(Boolean(anchorEl))
    // alert()
  },[anchorEl])
  const logOut = () => {
    auth.signOut();
    navigate('/login'); 
    setOpen(false);
  }


  const hndlChk1 = (x: React.ChangeEvent<HTMLInputElement>) => {
    // let value:any = x.target.value;
    let value: string = x.target.value;
    // チェックされたvalueが含まれているか判定する
    if (campaign.includes(value)) {
      // チェックが外された場合
      setCampaign(
        // 除いた配列を新たに作成する
        campaign.filter((v) => v !== value)
      );
    // チェックされた場合    
    } else {
      // チェックされたセレクトボックスのvalueを配列campaignに追加する
      setCampaign([...campaign, value]);
    }
  }

  const hndChinryouFrom=(x:any)=>{
    let num = Number(x.target.value)
    setChinryouFrom(num)
  }

  const hndChinryouTo=(x:any)=>{
    let num = Number(x.target.value)
    setChinryouTo(num)
  }

  const hndChikunen=(x:any)=>{
    console.log(x.target.value,"築年")
    setChikunen(x.target.value)
  }


  const hndKouzou=(x:any)=>{
    let value:any = x.target.value;
    if (kouzou.includes(value)) {
      setKouzou(
        kouzou.filter((v) => v !== value)
      );
    } else {
      setKouzou([...kouzou, value]);
    }
  }

  const hndMensekiU=(x:any)=>{
    let num = Number(x.target.value)
    setMensekiU(num)
  }

  const hndMensekiT=(x:any)=>{
    let num = Number(x.target.value)
    setMensekiT(num)
  }
  
  const id = open ? 'simple-popper' : undefined;
  const hndMadori=(x:any)=>{
    let value:any = x.target.value;
    let m:any = madori;
    if(value){
      if (m.includes(value)) {
        setMadori(
          m.filter((v:any) => v !== value)
        );
      } else {
        if(m){
          setMadori([...m, value]);
        }
      }
    }
  }
  // console.log(choumei)
  

  const hndSetsubi=(x:any)=>{
    let value:any = x.target.value;
    if (setsubi.includes(value)) {
      setSetsubi(
        setsubi.filter((v) => v !== value)
      );
    } else {
      setSetsubi([...setsubi, value]);
    }
  }


  const hndSearch= () =>{
    let search ={
      campaign: campaign,
      chomei:choumei,
      kouzou:kouzou,
      chinryou_from:chinryou_from,
      chinryou_to:chinryou_to,
      chinryouKyou:chinryouKyou,
      tikunen:chikunen,
      menseki_t:menseki_t,
      menseki_u:menseki_u,
      madori:madori,
      setsubi:setsubi,
    };
    
    props.searchVal(search)
    if(location.pathname != '/' && location.pathname != '/list'　&& location.pathname != '/List'){
        navigate('/')
    }
    
  };

  const hndClearSearch = () =>{
    setChinryouKyou(false)
    setCampaign([]);
    setChinryouFrom(0);
    setChinryouTo(0);
    setChikunen(0);
    setKouzou([]);
    setMensekiU(0);
    setMensekiT(0);
    setMadori([]);
    setSetsubi([]);
    setChoumei([]);
    let search = {
      campaign: [],
      chomei:[],
      kouzou:[],
      chinryou_from:0,
      chinryou_to:0,
      chinryouKyou:false,
      tikunen:0,
      menseki_t:0,
      menseki_u:0,
      madori:[],
      setsubi:[],
    };
    chomeiRef.current.clearArea()
    props.searchVal(search)
    if(location.pathname != '/' && location.pathname != '/list'　&& location.pathname != '/List'){
        navigate('/')
    }
  };
  const chomeiProps = { // make sure all required component's inputs/Props keys&types match
    Choumei:choumei,
    selectChoumei:setChoumei
  }

  return (
    <>
    <div style={{width:"300px",height:'100%',overflow:'visible',position: 'fixed',top: 0,left: 0,boxSizing:'border-box',backgroundColor:'#fff',zIndex: 10}}>
    <header className='header' style={{display:"block"}}>
      <div className='h_top'>
        <a href='/'>
          <img className='logo' src={`${process.env.PUBLIC_URL}/img/global_tree.png`} alt='global_tree'/>
        </a>
        <p className='site_title'>賃貸仲介業者様専用サイト</p>
        <div>
        <div className='users' onClick={handleClick}>
          <span className='icon' onClick={()=>setOpen(true)}><PersonOutlineIcon/></span>
          <span className='name'>{agent}</span>
        </div>
        <Popper style={{zIndex:10000}} id={id} open={open} anchorEl={anchorEl}>
          <Box className="Popup">
            <MenuList
              autoFocusItem={open}
              id="composition-menu"
              aria-labelledby="composition-button"
              // onKeyDown={handleListKeyDown}
            >
              <MenuItem onClick={() => {navigate('/passChange'); setOpen(false)}}>パスワード変更</MenuItem>
              {/* <Divider /> */}
              <MenuItem onClick={() => logOut()}>ログアウト</MenuItem>
              <MenuItem><a href='/termsofuse' style={{textDecoration:"none",color: "#555555",width: "100%"}}>利用規約</a></MenuItem>
            </MenuList>
          </Box>
        </Popper>
            </div>
      </div>
      
      <form className='search_box'>
        <div className='s_top'>
          <span className='icon'><SearchIcon/></span>
          <span>物件を検索する</span>
        </div>

        <div className='search_table'>
          {/* <div className='search_inner'>
            <h4 className='s_title'>キャンペーンなど</h4>
            <ul className='search_list'>
            {campaignList.map((item,i) => {
              return (
                <li className='list' key={'campaign'+i}>
                  <label className='clist' key={item.id}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      value={item.value}
                      onChange={hndlChk1}
                      checked={campaign.includes(item.value)}
                    />
                    <span className="checktext">{item.text}</span>
                  </label>
                </li>
              )
              })
            }
            </ul>
          </div> */}

          <div className='search_inner'>
            <h4 className='s_title'>町名</h4>
            <ChomeiTable ref={chomeiRef} {...chomeiProps}/>
          </div>

          <div className='search_inner'>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" className='searchbtn' onClick={hndSearch}>検　索</Button>
            </ThemeProvider>
          </div>

          <div className='search_inner'>
            <h4 className='s_title'>賃料</h4>
            <div>
                
            <ul className='search_list flex'>
                <li className='list'>
                <label className='clist'>
                    <input
                      className="checkbox"
                      type="checkbox"
                      value={Number(chinryouKyou)}
                      onChange={()=>setChinryouKyou(!chinryouKyou)}
                      checked={chinryouKyou}
                    />
                    <span className="checktext">共益費込み</span>
                </label>
                </li>
                </ul>
            </div>
            <div className='select-wrapper small'>
              <select className='select' onChange={hndChinryouFrom}>
                <option value="0">下限なし</option>
                <option value="20000">2万</option>
                <option value="25000">2.5万</option>
                <option value="30000">3万</option>
                <option value="35000">3.5万</option>
                <option value="40000">4万</option>
                <option value="45000">4.5万</option>
                <option value="50000">5万</option>
                <option value="55000">5.5万</option>
                <option value="60000">6万</option>
                <option value="65000">6.5万</option>
                <option value="70000">7万</option>
                <option value="75000">7.5万</option>
                <option value="80000">8万</option>
                <option value="85000">8.5万</option>
                <option value="90000">9万</option>
                <option value="95000">9.5万</option>
                <option value="100000">10万</option>
                <option value="110000">11万</option>
                <option value="120000">12万</option>
                <option value="130000">13万</option>
                <option value="140000">14万</option>
                <option value="150000">15万</option>
                <option value="200000">20万</option>
                <option value="300000">30万</option>
                <option value="500000">50万</option>
                <option value="1000000">100万</option>
              </select>
            </div>
            <p style={{margin: '12px 0',fontSize: "16px",float:"right"}}>~</p>

            <div className='select-wrapper small'>
              <select className='select' onChange={hndChinryouTo}>
                <option value="0">上限なし</option>
                <option value="20000">2万</option>
                <option value="25000">2.5万</option>
                <option value="30000">3万</option>
                <option value="35000">3.5万</option>
                <option value="40000">4万</option>
                <option value="45000">4.5万</option>
                <option value="50000">5万</option>
                <option value="55000">5.5万</option>
                <option value="60000">6万</option>
                <option value="65000">6.5万</option>
                <option value="70000">7万</option>
                <option value="75000">7.5万</option>
                <option value="80000">8万</option>
                <option value="85000">8.5万</option>
                <option value="90000">9万</option>
                <option value="95000">9.5万</option>
                <option value="100000">10万</option>
                <option value="110000">11万</option>
                <option value="120000">12万</option>
                <option value="130000">13万</option>
                <option value="140000">14万</option>
                <option value="150000">15万</option>
                <option value="200000">20万</option>
                <option value="300000">30万</option>
                <option value="500000">50万</option>
                <option value="1000000">100万</option>
              </select>
            </div>
          </div>


          <div className='search_inner'>
            <h4 className='s_title'>築年数</h4>
            <div className='select-wrapper'>
              <select className='select selectFull' onChange={hndChikunen}>
                {/* <option value={1}>新築</option>
                <option value={3}>3年以内</option> */}
                <option value={0}>指定なし</option>
                <option value={5}>5年以内</option>
                <option value={10}>10年以内</option>
                <option value={20}>20年以内</option>
                <option value={30}>30年以内</option>
              </select>
            </div>
          </div>


          <div className='search_inner'>
            <h4 className='s_title'>構造</h4>
            <ul className='search_list flex'>
            {kouzouList.map((item,i) => {
              return (
                <li className='list' key={'kouzou'+i}>
                  <label className='clist' key={item.id}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      value={item.value}
                      onChange={hndKouzou}
                      checked={kouzou.includes(item.value)}
                    />
                    <span className="checktext">{item.text}</span>
                  </label>
                </li>
              )
              })
            }
            </ul>
          </div>

          <div className='search_inner'>
            <h4 className='s_title'>面積</h4>
            <div className='select-wrapper small'>
              <select className='select' onChange={hndMensekiU}>
                <option value="0">下限なし</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
                <option value="60">60</option>
                <option value="65">65</option>
                <option value="70">70</option>
                <option value="75">75</option>
                <option value="80">80</option>
                <option value="85">85</option>
                <option value="90">90</option>
                <option value="95">95</option>
                <option value="100">100</option>
              </select>
            </div>
            <p style={{margin: '12px 0',fontSize: "16px",float:"right"}}>~</p>

            <div className='select-wrapper small'>
              <select className='select' onChange={hndMensekiT}>
                <option value="0">上限なし</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
                <option value="60">60</option>
                <option value="65">65</option>
                <option value="70">70</option>
                <option value="75">75</option>
                <option value="80">80</option>
                <option value="85">85</option>
                <option value="90">90</option>
                <option value="95">95</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div className='search_inner'>
            <h4 className='s_title'>間取</h4>
            <ul className='search_list flex madori'>
            {madoriList.map((item,i) => {
              return (
                <li className='list' key={'madori'+i}>
                  <label className='clist' key={item.id}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      value={item.value}
                      onChange={hndMadori}
                      checked={madori.includes(item.value)}
                    />
                    <span className="checktext">{item.text}</span>
                  </label>
                </li>
              )
              })
            }
            </ul>
          </div>


          <div className='search_inner'>
            <h4 className='s_title'>設備</h4>
            <ul className='search_list flex'>
            {setsubiList.map((item,i) => {
              return (
                <li className='list' key={'setsubi'+i}>
                  <label className='clist' key={item.id}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      value={item.value}
                      onChange={hndSetsubi}
                      checked={setsubi.includes(item.value)}
                    />
                    <span className="checktext">{item.text}</span>
                  </label>
                </li>
              )
              })
            }
            </ul>
          </div>
          {/* <div className='search_inner'>
            <div className='searchbtn' onClick={hndSearch}>
              検　索
            </div>
          </div> */}
          <div className='search_inner'>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" className='searchbtn' onClick={hndSearch}>検　索</Button>
            </ThemeProvider>
          </div>
          <div className='search_inner'>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" className='clearbtn' onClick={hndClearSearch}>検索条件クリア</Button>
            </ThemeProvider>
          </div>
        </div>
        
        <div>
        <p className="end">© 2024 株式会社グローバルセンター All rights reserved.</p>
        </div>
          
      </form>

    </header>
    </div>
    <div style={{width:"100%",paddingLeft:'300px'}}><Outlet /></div>
    </>

  );
}


export default Side;
