import React from 'react';
import { useEffect, useState } from 'react';
// import { compose, withStateHandlers } from "recompose";
import { connect } from 'react-redux';
import styled from 'styled-components'
// import ie from './Img/Map/icon_ie01.png'
// import GoogleMapReact from 'google-map-react'
// import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
// import './Css/Pin.css'
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import Button from '@mui/material/Button';


import {
    GoogleMap,
    // LoadScript,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";

// 参考サイト
// https://stackblitz.com/edit/react-umnzy4
// https://tomchentw.github.io/react-google-maps/


// const AnyReactComponent = ({ text }) => <div>{text}</div>;
const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    // mapTypeControl: false,
    // zoomControl: false,
    // scaleControl: false,
    // streetViewControl: false,
    // fullscreenControl: false,


  }
}




export default function Map(props) {
  const [lat,setLat] = useState(0)
  const [lng,setLng] = useState(0)

  useEffect(()=>{
    setLat(props.Ido)
    setLng(props.Keido)
  },[props])


  const center = {
    lat: lat,
    lng: lng,
  };
  


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo",
    language:'ja',
  })

  const containerStyle={
      width: "100%",
      height: "100%"
  };

  

  const [marker, setMarker] = useState(null);
  // const [marker, setMarker] = useState({lat: parseFloat(34.320201),lng:parseFloat(134.041338)});
  
  const onLoadMap = (map) => {
    // console.log(map.getSouthWest(),"aaaa")
    console.log(map)
    // const bounds = map.getBounds();
    // const minLatLng = bounds.getSouthWest();
    // const maxLatLng = bounds.getNorthEast();
    console.log(map)
		// mapRef.current = map
	}

  function handleChenge(event) {
    console.log(event,"event")
  }

  function handleMapClick(event) {
    setMarker({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
    })
  }

  
//   getShuhenList(){
//     if(this.shuhen.length == 0){
//         const b = this.$refs.map.$mapObject.getBounds();
//         let min_lat = b.getSouthWest().lat()
//         let max_lat = b.getNorthEast().lat()
//         let min_lng = b.getSouthWest().lng()
//         let max_lng = b.getNorthEast().lng()
//         const url = "https://orooms.global-center.co.jp/api/v1/search/rooms/spotDetail";
//         this.$axios.$post(url,{min_lat:min_lat,max_lat:max_lat,min_lng:min_lng,max_lng:max_lng}).then((res) => {
//             this.shuhen = res;
//             this.shuhenParking = res.filter(x => x.category == 'parking')
//             this.shuhenConvenienceStore = res.filter(x => x.category == '2')
//             this.shuhenSuperMarket = res.filter(x => x.category == '1')
//             this.shuhenBankPost = res.filter(x => x.category == '18' || x.category == '5')
//             this.shuhenNursery = res.filter(x => x.category == '13' || x.category == '14')
//             this.shuhenSchool = res.filter(x => x.category == '19' || x.category == '6')
//             this.shuhenDrug = res.filter(x => x.category == '17')
//             this.shuhenHospital = res.filter(x => x.category == '3' || x.category == '106')
//         });
//     }
// },


  // const [lord,setLoad] = useState(false);

  // useEffect(()=>{
  //   if(isLoaded){
  //     try{
  //       // var bounds = window.google.maps.LatLngBounds;
  //       var bounds = window.google.maps;
        
  //       // var aaaa =bounds.getSouthWest()
  //       // const southWest = bounds.getSouthWest();
  //       // const northEast = bounds.getNorthEast();

  //       console.log(bounds,"map")
  //     }catch(e){
  //       console.log(e,"e")
  //     }
     
  //   }

  // },[isLoaded])

  const [initialBounds, setInitialBounds] = useState(null);

  const onLoad = (map) => {
    if(lat&&lng){
      console.log(map)
    }
    
    // const bounds = map.getBounds();
    // const ne = bounds.getNorthEast();
    // const sw = bounds.getSouthWest();
    // const initialBoundsData = {
    //   southwest: { lat: sw.lat(), lng: sw.lng() },
    //   northeast: { lat: ne.lat(), lng: ne.lng() },
    // };
    // setInitialBounds(initialBoundsData);
  };
  

  return (

    <div>
      <div style={{ height: '500px', width: '100%' }}>
        
        {/* <LoadScript googleMapsApiKey='AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'> */}
        {isLoaded?
          <GoogleMap 
            center={center}
            // center={{lat: parseFloat(lat),lng:parseFloat(lng)}} 
            zoom={17}
            //zoomでデフォルトで表示される地図の範囲を指定します。
            mapContainerStyle={containerStyle}
            // onLoadMap={(map) => handleChenge(map)}
            // onLoadMap={handleChenge}
            onLoad={onLoad}
          >    
            {/* <Marker position={marker} /> */}
              <Marker 
              position={center}
              // position={{lat: parseFloat(lat),lng:parseFloat(lng)}} 
              icon={{
                  url: `${process.env.PUBLIC_URL}/img/mapicon/icon-ie.png`,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(25, 25),
                  scaledSize: new window.google.maps.Size(35, 55)
              }}
              // onChenge={()=>handleChenge}
              />
              

            {/* <Marker
              position={{lat: parseFloat(34.320201),lng:parseFloat(134.041338)}}
              icon={{
                  url: `${process.env.PUBLIC_URL}/img/mapicon/icon_marker_active.svg`
              }}
          ></Marker> */}
          
          </GoogleMap>:""}
        {/* </LoadScript> */}
      </div>

      {/* 四つ角の緯度経度が取れるようになったら戻す */}
      {/* <div id='MapBtns'>
        <Button id='ParkingiBtn' className='mapbtns'>
            <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_parking.png`} alt=''/>
        </Button>

        <Button id='ConbiniBtn' className='mapbtns'>
            <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_conbini.png`} alt=''/>
        </Button>

        <Button id='SuperBtn' className='mapbtns'>
            <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_super.png`} alt=''/>
        </Button>

        <Button id='BankBtn' className='mapbtns'>
            <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_bank.png`} alt=''/>
        </Button>

        <Button id='YouchienBtn' className='mapbtns'>
            <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_youchien.png`} alt=''/>
        </Button>

        <Button id='SchoolBtn' className='mapbtns'>
            <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_school.png`} alt=''/>
        </Button>

        <Button id='DSBtn' className='mapbtns'>
          <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_ds.png`} alt=''/>
        </Button>

        <Button id='HospitalBtn' className='mapbtns'>
          <img src={`${process.env.PUBLIC_URL}/img/mapicon/btn_hospital.png`} alt=''/>
        </Button>
      </div> */}

    </div>    
    )
}


// const Pin = styled.div``

// const mapStateToProps = state => {
//   return {
    
//   }
// }
// function mapDispatchToProps(dispatch) {
//   return {
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(App);