import React, { useEffect, useState, useContext, createContext  } from 'react';
import Search from './Componets/Search/search2';
import LayoutSide from './Componets/Layout/LayoutSide';
// import Search from './Componets/Search/search';
import Detail from './Componets/Detail/Detail';
import Termsofuse from './Componets/Termsofuse/Index';
import Login from './Componets/Login/Login';
import SetPass from './Componets/Login/setPass';
import Change from './Componets/Login/Change';
import Reset from './Componets/Login/Reset';
import Map from './Componets/Map/map';
import { Route,Routes } from 'react-router-dom';

import { AuthProvider } from './Componets/Login/authContext';
const SearchContext = createContext({})
export const useSearchContext = () => {
    return useContext(SearchContext);
};

function App() {
    const [search,setSearch] = useState<object>({})
  return (
    <AuthProvider>
    <div>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/yakuhanjp@4.0.1/dist/css/yakuhanjp.css"></link>
       <link href="https://use.fontawesome.com/releases/v5.0.6/css/all.css" rel="stylesheet"></link> {/*アイコン用のcss */}
       
       <SearchContext.Provider value={{search}}>
      <Routes>
        <Route element={<LayoutSide searchVal={setSearch}/>} >
            <Route path='/list' element={<Search />} />
            <Route path='/termsofuse' element={<Termsofuse />} />
            {/* <Route path='/detail' element={<Detail />} /> */}
            <Route path='/detail/:tid/:rid' element={<Detail />} />
            <Route path='/' element={<Map />} />
        </Route>  
            <Route path='/login' element={<Login />} />
            <Route path='/passChange' element={<Change />} />
            <Route path='/passReset' element={<Reset />} />
            <Route path='/setPass' element={<SetPass />} />
      </Routes>
        </SearchContext.Provider>
    </div>
    </AuthProvider>
  );
}

export default App;
