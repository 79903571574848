import React from 'react';
// import { useEffect, useState } from 'react';

import './style.css';
import Termsofuse from './Termsofuse';

export default function TermsofuseIndex(props) {
  // var open = props.open;
    const now = new Date();
    const year = now.getFullYear();

    return (
        <main>
          <div className='Termsofuse'>
            <Termsofuse/>
          </div>
          <p className="copyright">
            © 2003-{year} GLOBAL CENTER,INC
          </p>
        </main>
    )
}