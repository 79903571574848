import React from 'react';
// import { useEffect, useState } from 'react';

import './style.css';

export default function Termsofuse(props) {
  // var open = props.open;

    return (
        <div>
          <div class="message">
            <h1 class="title_style1">「グローバルセンター 賃貸仲介業者様専用サイト」利用規約</h1> 
            <p class="title_style1-sub">terms of use</p>
          </div>
          <div className='inner'>
            第１条（適用関係）<br/>
            １．本規約は、株式会社グローバルセンター（グループ会社も含みます。以下、「弊社」といいます。）が運営する賃貸不動産情報提供サービス「賃貸仲介業者様専用サイト」（以下、「本サービス」といいます。）の利用に関して適用されます。本規約は、弊社と本サービスを利用する宅地建物取引業者（以下、「利用者」といいます。）との間における一切の関係に適用されます。<br/>
            ２．本サービスは、利用者が弊社の不動産物件に関する情報をインターネット上で検索し、契約関連資料を取得することにより、不動産情報の提供を図るサービスです。また本サービスを通じて弊社の管理する不動産（以下、「物件」といいます。）を仲介することにより利用者利益の向上を目的とします。<br/>
            ３．弊社は、本サービスの運営上、本サービスを構成する個別のサービス毎に、本規約の他別途に利用約款や利用上の注意等の諸規定を設けることがあります。それらの個別規定は、本サービスに関する規約の一部を構成するものとし、個別規定が本規約に定める内容と異なる場合、個別規定の内容が優先されます。<br/>
            ４．利用者によるサービス利用申込書の弊社への提出により、利用者は本規約を承諾したものとみなします。 <br/>
            <br/>
            第２条（本サービスの利用）<br/>
            １．本サービスは、以下の提供内容です。ただし、この内、弊社が利用者に提供するサービス内容は、現時点において提供可能なもののみとします。<br/>
              ①弊社管理物件の賃貸不動産の物件情報検索<br/>
              ②弊社管理物件の賃貸不動産の賃貸借契約関連資料の取得<br/>
            ２．利用者は、本サービスを無料で利用できるものとします。<br/>
            ３．本サービスの利用にあたり、利用者は本規約および弊社の指示に従うものとします。<br/>
            ４．利用者は本サービスを通じて取得する情報内容に関しては、正確性の確認を含む一切の責任を自ら負うものとし、弊社に対し損害賠償等一切の異議申立をしないものとします。<br/>
            ５．利用者は、本サービスを利用するために必要な通信機器を含むすべての機器を利用者の責任と負担で準備するものとします。<br/>
            ６．本サービスの使用により、利用者のコンピュータなどに障害が生じた場合も弊社はいかなる補償も行わないものとします。<br/>
            ７．本サービスの利用に伴い生じた利用者、入居希望者、賃貸人、賃借人、その他第三者の間の紛争は当事者間で解決するものとし、弊社はいかなる補償も行わないものとします。<br/>
            <br/>
            第３条（利用者の定義）<br/>
            １．利用者は、本サービスを利用するに際し、申込書をもとに次の各号に条件を満たしていることを確認します。<br/>
              ①宅地建物取引業法に定める宅地建物取引業者免許（以下、「免許」といいます。）を有し、かつ宅地建物取引業を営んでおり、宅地建物取引業法を遵守していること<br/>
              ②不動産の表示に関して、公正競争規約を順守していること<br/>
              ③不動産業において、景品類提供制限に関する公正競争規約を順守していること<br/>
              ④業務停止等の行政処分期間中でないこと<br/>
              ⑤弊社との過去の取引において、料金の未払い等の問題が生じたことがないこと<br/>
              ⑥上記各号のほか、法令及び取引通念に照合し、業務内容、経営状態、広告表示等において特段の問題がないこと<br/>
            ２．利用者の申込時において、弊社は宅地建物取引業者名簿の確認等の審査を実施します。審査の結果弊社は当該利用者に本サービスの利用を認めないこともあります。<br/>
            ３．	利用者は前項の審査の場合、弊社に協力するものとします。 <br/>
            <br/>
            第４条（利用者情報の変更）<br/>
            １．利用者は、商号又は名称、代表者氏名、事務所の所在地等、弊社に届け出ている内容について変更が生じた場合には、速やかに届け出るものとします。利用者情報の変更は、利用者本人の申し出により行います。<br/>
            ２．原則として、利用者の地位及び権利の譲渡はできないものとします。<br/>
            ３．利用者情報の不備、変更手続の不履行や遅延等により利用者が不利益を被ったとしても、弊社はいかなる責任も負いません。<br/>
            <br/>
            第５条（利用者による解約）<br/>
            １．利用者は、本サービスの利用を終了する場合、終了を希望する前月（当該日が弊社の営業日であること。）までに弊社に届け出るものとし、この場合、サービスの利用終了を希望する月の末日（当該日が弊社の営業日であること。）をもって弊社は本サービスの提供を終了するものとします。ただし、利用終了の理由が廃業である場合は、免許権者への廃業の届出を完了する月の末日もしくは免許の効力が失われた日のいずれか早い日をもって終了するものとします。<br/>
            ２．弊社は、利用者の利用中止に伴い、利用中止により発生した一切の損害に対する責任を負わないものとします。 <br/>
            <br/>
            第６条（個人情報）<br/>
            １．弊社は、利用者が本サービスの申込又は利用等を通じて弊社が知り得た情報について、「個人情報保護に関する法律」等の法令を遵守し、善良なる管理者の注意を持って管理します。<br/>
            ２．利用者は、弊社の個人情報保護方針について、弊社の運用するウェブサイト内にて確認するものとし、弊社の個人情報保護方針に同意できない場合は本サービスをご利用いただけません。利用者が本サービスを利用したことをもち、弊社の個人情報保護方針に同意したものとみなします。<br/>
            <br/>
            第７条（利用者ＩＤ及びパスワードの管理）<br/>
            １．	弊社は、利用者が申込後、本サービスを利用するためのパスワードを発行します。<br/>
            ２．	IDとして利用者の所持するメールアドレスを使用します。<br/>
            ３．利用者は、利用者自身のＩＤおよびパスワードを第三者に譲渡もしくは使用させる行為はできないものとします。<br/>
            ４．利用者は、利用者自身のＩＤおよびパスワードの管理・使用について一切の責任を負い、その使用に係わる一切の債務を負うものとします。<br/>
            ５．利用者のＩＤおよびパスワードを第三者が使用したことによる債務や損失等が発生した場合、利用者が一切の責任及び負担を負うものとします。<br/>
            <br/>
            第８条（利用者の地位取消）<br/>
            １．利用者が次の各号のいずれかに該当する場合、弊社は事前に通知することなく、直ちに利用者の本サービスの利用を終了させることができるものとします。<br/>
            　①暴力、威力もしくは詐欺的手法を駆使して経済的利益を追求する集団もしくは個人(いわゆる反社会的勢力)に属する、もしくは密接な関係を有することが判明した場合<br/>
            　②弊社への申告、届け出の内容に虚偽があった場合<br/>
            　③免許取消処分等の事由により免許を喪失した場合<br/>
            　④弊社の業務改善要求に対して業務改善が認められないと弊社が判断した場合<br/>
              ⑤不要不急の問合せや、悪質ないたずら、正当な理由なくサービス提供要求をする場合<br/>
            　⑥その他弊社が利用者として不適切とみなした場合<br/>
            ２．1年間本サービスの利用がない場合で利用者から 特段の届け出がない場合は、弊社から事前に通知することなくアカウントを削除するものとします。<br/>
            ３．前項により利用者の本サービスの利用を停止した場合であっても、弊社は利用停止により発生した一切の損害に対して責任を負わないものとします。 <br/>
            <br/>
            第９条（データベースの著作権）<br/>
            弊社のサーバに格納され、データベース著作物として再編集、加工された情報について、その利用権ならびに著作権（著作権法に記載する条文を含む。）を含む知的所有権等一切の権利は、弊社のみに帰属するものとします。 <br/>
            <br/>
            第１０条（サービスの変更、一時中断、停止、および終了）
            １．弊社は、本サービスの運用上、弊社が必要と判断した場合には利用者の承諾及び事前の通知なく、本サービスを内容変更または終了できるものとします。<br/>
            ２．弊社が前項の規定により本サービスを内容変更または終了する場合には、利用者に対して弊社が運営するウェブサイトもしくはアプリ上で告知するものとし、会員は異議なく承諾します。 <br/>
            ３．弊社は次の各号に該当すると判断した場合、利用者への事前通知をせず一時的に本サービスを中断もしくは停止することができるものとします。<br/>
            　①本サービスにおけるシステムの保守点検を定期的に、または緊急に行う場合<br/>
              ②火災、停電などにより、本サービスの提供困難になった場合<br/>
              ③地震、噴火、洪水、津波などの天災により、本サービスの提供が困難になった場合<br/>
              ④戦争、変乱、暴動、争乱、労働争議などにより、本サービスの提供が困難になった場合<br/>
              ⑤その他、運用上、または技術上等の理由から、弊社が本サービスの一時的な中断を必要と判断した場合<br/>
            ３．	弊社は、本サービスの変更、一時中断、停止および終了に起因する一切の損害に対して、補償を免責されるものとします。<br/>
            <br/>
            第１１条（規約の追加変更）<br/>
            １．本規約において、運営上の追加または変更の必要が生じた場合、利用者の承諾を得ることなく弊社は本規約を変更できるものとします。<br/>
            ２．前項の変更を行う場合、弊社は本サービス内で利用者に通知もしくは公表するものとし、利用者はこれを異議なく承諾します。<br/>
            <br/>
            第１２条（協議）<br/>
            本規約に定めのない事項については、関係法令に従う他、利用者と弊社は誠意をもって協議し、解決を図るものとします。 
            <br/>
            第１３条（免責）<br/>
            １．弊社は、本サービスの運営に関して故意又は重大な過失がない限り、利用者に対し損害賠償義務を一切負わないものとします。<br/>
            ２．本サービスの掲載情報に齟齬があった場合は、現況を優先します。<br/>
            <br/>
            第１４条（管轄裁判所）<br/>
            本サービスについて紛議が生じた場合、高松地方裁判所第一審の裁判所とすることを合意します。<br/>
            
            <div className='ijo'>
              以上<br/>
              制定日　　２０２４年５月２４日
            </div>
          </div>
        </div>
    )
}