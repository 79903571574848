import React,{useRef} from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import './css/list.css';
import moment from 'moment';
import SubjectIcon from '@mui/icons-material/Subject';
import PlaceIcon from '@mui/icons-material/Place';


import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalPrintshopIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom';
import { useSearchContext } from '../../App';
import { useAuthContext } from '../Login/authContext';


type Post = {
  Tatemono_Id: string;
  Room_Id: number;
  Tatemono_Name: string;
  Tatemono_Kana: string;
  Room_Name: string;
  Zip_1: string;
  Zip_2: string;
  Todoufuken: string;
  Shikuchoson: string;
  Other_Address: string;
  Choiki: string;
  shiharai_Chukai: number;
  Kanri_Kubun_No: number;
  Kuushitsu_Joukyou_No: number;
  Shunkou_Year: number;
  Shunkou_Month: number;
  Kouzou_No: number;
  Kouzou_Name: string;
  Madori_No: number;
  Madori_Name: string;
  Senyuu_Menseki: number;
  Room_Muki_No: number;
  Room_Muki_Name: string;
  ChinRyou: number;
  KyouekiHi: number;
  SuidouRyou: string;
  P_Ryou: number;
  ShikiKin: number;
  ReiKin: number;
  Teigaku_Cleaning: string;
  Hoken: string;
  jimu_tesuryou:string;
  Nyuukyo_Kanou_Year: number;
  Nyuukyo_Kanou_Month: string;
  Nyuukyo_Kanou_Day: string;
  Nyuukyo: number;
  Kagi_Ban: number;
  Kagi_Shozai_Name: string;
  Ido: number;
  Keido: number;
  // is_gakusei_yoyaku: number;
  ad_flag: number;
  ad_fee: number;
  ad_month: number;
};


function getNyuukyo(year:string, month:string, day:string) {
  var hikakuDate = "";
  var now = moment().format("YYYY-MM-DD");
  var disp_day = day;
  // 入居可能日が入っている場合
  if (year !== "" && month !== "" && day !== "" && day !== "-") {
    var jiki;
    if (day === "上旬") {
      jiki = "10";
    } else if (day === "中旬") {
      jiki = "20";
    } else if (day === "下旬") {
      var lastDate = moment(year + month + "01").endOf("month");
      jiki = moment(lastDate).get("date");
    } else {
      // 日付が入力されている場合
      jiki = day;
      disp_day = day + "日";
    }
    hikakuDate = moment(year + month + jiki).format("YYYY-MM-DD");
    if (moment(hikakuDate).isBefore(now)) {
      return "即";
    }
    return year + "年" + month + "月" + disp_day;
  } 
  else {
    return "即";
  }
// return "即";
}


function truncateString(inputString:string, maxLength:number) {
  if (inputString.length > maxLength) {
    return inputString.slice(0, maxLength) + "…";
  } else {
    return inputString;
  }
}


function App() {
  const { user, load } = useAuthContext();
  const navigate = useNavigate();
  const  searchdata = useSearchContext() as { search?: any };
  const [posts, setPosts] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();

  const [pageSize, setPageSize] = useState<number>(50);

  const [columnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')
  
  const [isLoading, setIsLoading] = useState(false);

  const [dltid, setDownloadTid] = useState("");
  const [dlrid, setDownloadRid] = useState(0);
  const [DownloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    const getPosts = async () => {
      try{
        setIsLoading(true);
      const response = await fetch(
        'https://api-d.homestation.jp/searchRooms/getList'
        // 'http://localhost:8080/searchRooms/getList'
      );
      const data = await response.json();
      console.log(JSON.parse(data),"getListData")
      setPosts(JSON.parse(data));
      }finally{
        setIsLoading(false);
      }
    };
    const getPostsSearch = async () => {
        try{
            setIsLoading(true);
            const response = await fetch('https://api-d.homestation.jp/searchRooms/searchList', {
            // const response = await fetch('http://localhost:8080/searchRooms/searchList', {
                method: 'post',
                body: JSON.stringify(searchdata.search),
                headers: { 'Content-type': 'application/json; charset=UTF-8' },
            })
            
            const data = await response.json();
            setPosts(JSON.parse(data));
        }finally{
            setIsLoading(false);
        }
      
    };
    
    const getLogs = async (value:any) => {
        if(user){
            try{
                let body = value
                body.id = user.uid
                // setIsLoading(true);
                const response = await fetch('https://api-d.homestation.jp/searchRooms/searchLogs', {
                // const response = await fetch('http://localhost:8080/searchRooms/searchLogs', {
                    method: 'post',
                    body: JSON.stringify(body),
                    headers: { 'Content-type': 'application/json; charset=UTF-8' },
                })
                
                // const data = await response.json();
                // setPosts(JSON.parse(data));
            }finally{
                // setIsLoading(false);
            }
        }
    };
    if(searchdata && Object.keys(searchdata).indexOf('search') != -1 &&  Object.keys(searchdata.search).length > 0){
        
        getLogs(searchdata.search)
        getPostsSearch();
    }else{
        let body = {
            chomei:[],
            chinryou_to:0,
            chinryou_from:0,
            tikunen:0,
            menseki_t:0,
            menseki_u:0,
            madori:[],
            setsubi:[],
            kouzou:[]
        }
        getPosts();
        getLogs(body)
    }
    
  }, []);

  useEffect(() => {
    if(load && !user){
        navigate('/login')
    }
  }, [load]);
  
  useEffect(() => {
    const getPosts = async () => {
        try{
            setIsLoading(true);
            const response = await fetch('https://api-d.homestation.jp/searchRooms/searchList', {
            // const response = await fetch('http://localhost:8080/searchRooms/searchList', {
                method: 'post',
                body: JSON.stringify(searchdata.search),
                headers: { 'Content-type': 'application/json; charset=UTF-8' },
            })
            
            const data = await response.json();
            setPosts(JSON.parse(data));
        }finally{
            setIsLoading(false);
        }
      
    };
    const getLogs = async () => {
        try{
            let body = searchdata.search
            body.id = user.uid
            // setIsLoading(true);
            const response = await fetch('https://api-d.homestation.jp/searchRooms/searchLogs', {
            // const response = await fetch('http://localhost:8080/searchRooms/searchLogs', {
                method: 'post',
                body: JSON.stringify(body),
                headers: { 'Content-type': 'application/json; charset=UTF-8' },
            })
            
            // const data = await response.json();
            // setPosts(JSON.parse(data));
        }finally{
            // setIsLoading(false);
        }
      
    };
     
    if(searchdata && Object.keys(searchdata).indexOf('search') != -1 &&  Object.keys(searchdata.search).length > 0){
        getPosts();
        getLogs();
    }
  }, [searchdata]);
  async function DownloadDaicho(info:any){
    setDownloadTid(info.Tatemono_Id)
    setDownloadRid(info.Room_Id)
    setDownloadLoading(true)
    
    if(user){
        var uid = user.uid
        // var url = 'http://localhost/laravelapi/public/api/ChukaiDaicho/' + info.Tatemono_Id + '/' + info.Room_Id
        var url = 'https://doc.homestation.jp/api/ChukaiDaicho/' + info.Tatemono_Id + '/' + info.Room_Id
        const response = await fetch(url, {
            method: 'post',
            body: JSON.stringify({uid:uid}),
            // headers: { 'Accept': 'application/pdf' },
                headers: { 'Content-type': 'application/json; charset=UTF-8','Accept': 'application/pdf'  },
        })
        const data = await response.blob();    
        const a = document.createElement('a');
        a.download = info.Tatemono_Name + '_' + info.Room_Name;
        a.href = URL.createObjectURL(data);
        a.click();
        setDownloadLoading(false)
    }
  } 
  
  // テーブルセルのカスタマイズ
  const columns = [
    // columnHelper.accessor((row) => `${row.userId} ${row.id}`, {id: 'WID'}),   //  複数列をまとめる場合
   

    // columnHelper.accessor('is_gakusei_yoyaku', {
    //   header: '予約',
    //   id:'yoyaku',
    //   enableResizing: false,  //  列幅固定
    //   size: 60,
    //   cell: (info) => (
    //     info.row.original.is_gakusei_yoyaku ? 
    //     <CheckCircleIcon style={{color:"#3CB4FF",marginTop:"5px"}}/>
    //   : <></>)
    // }),
    
    

    columnHelper.accessor('Room_Id', {
      header: () => '資料',
      id:'print',
      enableResizing: false,  //  列幅固定
      size: 65,
      enableSorting: false, // 列のソート無効
      cell: (info) => (
        <div className='printBtn' style={{cursor:'pointer'}} onClick={()=>DownloadDaicho(info.row.original)}>
          {dltid === info.row.original.Tatemono_Id && dlrid === info.row.original.Room_Id && DownloadLoading ? 
          <CircularProgress color="inherit" style={{width:"15px",height:"15px"}} />:<LocalPrintshopIcon />
          }
          <span> 資料</span>
        </div>
      ),
    }),
    columnHelper.accessor('Tatemono_Name', {
      header: '物件名',
      enableResizing: false,  //  列幅固定
      size: 190,
      cell: (info) => (
        <a className='detailBtn' target="_blank" href={'/detail/'+ info.row.original.Tatemono_Id +'/'+ info.row.original.Room_Id} rel="noopener noreferrer">
          <p className='tatemonoName'>{info.row.original.Tatemono_Name}</p>
          <OpenInNewIcon style={{color:"#000"}}/>
        </a>
      ),
    }),
    columnHelper.accessor('Room_Name', {
      header: () => '号室',
      enableResizing: false,  //  列幅固定
      enableSorting: false, // 列のソート無効
      size: 50,
    }),


    columnHelper.accessor((row) => `${row.Shikuchoson}${row.Choiki}${row.Other_Address}`,{
      header: "住所",
      id: "Address",
      enableResizing: false,  //  列幅固定
      size: 140,
      cell: (info) => (
        <div className='addressbox'>
          <div className='mapBtn'>
            <a target="_blank" href={'https://maps.google.com/maps?ll='+ info.row.original.Ido +','+ info.row.original.Keido +'&z=16&q='+ info.row.original.Ido +','+ info.row.original.Keido} rel="noopener noreferrer">MAP</a>
          </div>
          <p style={{margin:0}}>
          {info.row.original.Shikuchoson==="高松市"?truncateString(info.row.original.Choiki, 5)
          :truncateString(info.row.original.Shikuchoson+info.row.original.Choiki, 5)}
          </p>
        </div>
      ),
    }),


    columnHelper.accessor('ChinRyou', {
      header: () => '家賃',
      // enableSorting: false, // 列のソート無効
      size: 70,
      cell: (info) => (
        <div>
          {info.row.original.ChinRyou.toLocaleString()}円
        </div>
      ),
    }),

    columnHelper.accessor('Madori_Name', {
      header: () => '間取',
      // enableSorting: false, // 列のソート無効
      size: 60,
    }),

    columnHelper.accessor((row) => `${row.Shunkou_Year}年${row.Shunkou_Month}月`,{
      header: () => '竣工年月',
      id: 'Shunkou',
      size: 90,
    }),


    columnHelper.accessor('KyouekiHi', {
      header: () => '共益費',
      enableSorting: false, // 列のソート無効
      size: 70,
      cell: (info) => (
        <div>
          {info.row.original.KyouekiHi > 0 ? info.row.original.KyouekiHi.toLocaleString()+"円":info.row.original.KyouekiHi}
        </div>
      ),
    }),

    columnHelper.accessor('SuidouRyou', {
      header: () => '水道料',
      enableSorting: false, // 列のソート無効
      size: 100,
    }),

    columnHelper.accessor('P_Ryou', {
      header: () => '駐車場',
      size: 110,
    }),

    columnHelper.accessor('Kouzou_Name', {
      header: () => '構造',
      size: 90,
      cell: (info) => (
        <div>
          {info.row.original.Kouzou_Name}
          {/* {info.row.original.Kouzou_Name === "鉄筋コンクリート" ? 
          <span>鉄筋<br/>コンクリート</span>
          :(info.row.original.Kouzou_Name === "鉄骨鉄筋コンクリート"?
          <span>鉄骨鉄筋<br/>コンクリート</span>
          :info.row.original.Kouzou_Name)} */}
        </div>
      ),
    }),

    columnHelper.accessor('Senyuu_Menseki', {
      // header: () => '面積(㎡)',
      header: () => <span style={{width:"60px",textAlign:"center",display:'ruby'}}>面積<br/>(㎡)</span>,
      size: 60,
    }),

    columnHelper.accessor('Room_Muki_Name', {
      header: () => '向き',
      size: 60,
    }),

    columnHelper.accessor('ShikiKin', {
      header: () => '敷金',
      size: 70,
      cell: (info) => (
        <div>
          {info.row.original.ShikiKin > 0 ? info.row.original.ShikiKin.toLocaleString()+"円":info.row.original.ShikiKin}
        </div>
      ),
    }),
    columnHelper.accessor('ReiKin', {
      header: () => '礼金',
      size: 70,
      cell: (info) => (
        <div>
          {info.row.original.ReiKin > 0 ? info.row.original.ReiKin.toLocaleString()+"円":info.row.original.ReiKin}
        </div>
      ),
    }),

    columnHelper.accessor('Teigaku_Cleaning', {
      header: () => <span>定額<br/>クリーニング</span>,
      enableSorting: false, // 列のソート無効
      size: 110,
    }),

    columnHelper.accessor('Hoken', {
      header: () => '保険',
      enableSorting: false, // 列のソート無効
      size: 70,
    }),

    columnHelper.accessor('jimu_tesuryou', {
      header: () => <span>事務<br/>手数料</span>,
      enableSorting: false, // 列のソート無効
      size: 70,
      cell: (info) => (
        <div>
          {/* {info.row.original.jimu_tesuryou > 0 ? info.row.original.jimu_tesuryou.toLocaleString()+"円":info.row.original.jimu_tesuryou} */}
          {info.row.original.jimu_tesuryou ? '11,000円' : '-'}
        </div>
      ),
    }),

    columnHelper.accessor((row) => `${row.shiharai_Chukai}%`,{
      header: () => <span>支払<br/>仲介料</span>,
      id: 'shiharai',
      size: 55,
      enableSorting: false, // 列のソート無効
    }),
    
    columnHelper.display({
      header: () => <span>AD<br/>広告料</span>,
      id: "AD",
      size: 60,
      enableSorting: false, // 列のソート無効
      cell: (info) => (
        <div>
        {info.row.original.ad_flag == 1 ? <span>{info.row.original.ad_fee.toLocaleString()}円</span> :
          info.row.original.ad_flag == 2 ? <span>{info.row.original.ad_month}ヵ月</span> :
          "-"  }  
        </div>
      ),
    }),

    columnHelper.accessor((row) => `${row.Nyuukyo_Kanou_Year}${row.Nyuukyo_Kanou_Month}${row.Nyuukyo_Kanou_Day}`,{
      header: "入居",
      id: "Nyuukyo",
      size: 70,
      enableSorting: false, // 列のソート無効
      cell: (info) => (
        <div>
          {/* {info.row.original.Nyuukyo_Kanou_Year+"年"+info.row.original.Nyuukyo_Kanou_Month+"月"+info.row.original.Nyuukyo_Kanou_Day} */}
          {getNyuukyo(String(info.row.original.Nyuukyo_Kanou_Year),info.row.original.Nyuukyo_Kanou_Month,info.row.original.Nyuukyo_Kanou_Day)}
        </div>
      ),
    }),

    columnHelper.accessor('Kagi_Shozai_Name', {
      header: () => '鍵所在',
      id: "Kagi",
      enableSorting: false, // 列のソート無効
      size: 70,cell: (info) => (
        <div>
          {/* {info.row.original.Nyuukyo_Kanou_Year+"年"+info.row.original.Nyuukyo_Kanou_Month+"月"+info.row.original.Nyuukyo_Kanou_Day} */}
          {info.row.original.Kagi_Shozai_Name ? info.row.original.Kagi_Shozai_Name : '要確認'}
        </div>
      ),
    }),
    
   
  ];

  const table = useReactTable({
    data: posts,
    columns: columns,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    initialState: {
      pagination: {
        pageSize: 50,   //  表示数はここで変更
      }
    }
  });


  
  const pageCount = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;
  
  const pagesToShow = Array.from({ length: Math.min(5, pageCount - currentPage) }, (_, i) => currentPage + i);



  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  const changeBackgroundColor = (rowIndex:any) => {
    setClickedRowIndex(rowIndex);
  };


  const tableRef = useRef<HTMLDivElement | null>(null);


  const handleAdditionalClick = () => {
    setClickedRowIndex(null);//背景色を解除
    // refを使用して要素にアクセス tableのスクロール位置を上に
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
      tableRef.current.scrollLeft = 0;
    }
  };


  return user && load? (
    <main style={{ margin: '1em 2em' }}>

      {isLoading?
      <Box className="lodingbox">
        <div className='inner'> 
        <CircularProgress style={{'color': '#4ab7f4'}}/>
        </div>
      </Box>
      :""}

      <div className='top_flex' style={{fontFamily:"Noto Sans JP,sans-serif"}}>
      <div className='BtnBox'> 
          <div className='btns' style={{cursor: 'pointer'}} onClick={()=>navigate('/')}>
            <span className='icon'>
              <PlaceIcon/>
            </span>
            <span className='text'>地図表示</span>
          </div>
          <a className='btns blue'>
            <span className='icon'>
              <SubjectIcon/>
            </span>
            <span className='text'>一覧表示</span>
          </a>
        </div>
        <div className='hyoujibox'>
            <div className='total'>
            検索結果 <span className='num' style={{fontFamily:"Roboto,sans-serif"}}>{posts.length}</span><span className='ken'>件</span>
            </div>
            <div className='hyouji'>
            <div className='text'>表示件数</div>
            <select
                value={pageSize}
                onChange={(e) => {
                table.setPageSize(parseInt(e.target.value));
                setPageSize(parseInt(e.target.value));
                }}
            >
                <option value={10}>10件</option>
                <option value={30}>30件</option>
                <option value={50}>50件</option>
                <option value={100}>100件</option>
            </select>
            </div>
        </div>
      </div>



      <div className='tablebox' ref={tableRef} id="scrollSet" style={{overflowX: 'scroll', width: 'auto'}}>
        <table
          width={"100%"}
        >
        <thead>
          {table.getHeaderGroups().map((headerGroup,i) => (
              <tr key={'listTr'+i}>
                {headerGroup.headers.map((header) => (
                  <th 
                  {...{
                    key: i,
                    valign:"middle",
                    // colSpan: header.colSpan,
                    style: {
                      width: header.getSize(), 
                      left: header.id === "print"?"0px": (header.id === "Tatemono_Name"?"65px": (header.id === "Room_Name"?"261px": (header.id === "Address"?"311px":""))),
                      zIndex: (header.id === "print"||header.id === "Tatemono_Name"||header.id === "Room_Name"||header.id === "Address") ? 3 : 2,
                    },
                  }}
                    // key={header.id}
                    // // onClick={header.column.getToggleSortingHandler()} // 並び替えに必要
                    // onMouseDown={header.getResizeHandler()}
                    // onTouchStart={header.getResizeHandler()}
                    // style={{
                    //   // ***userId列とid列を左に固定***
                    //   position: (header.id === "userId" || header.id === "id") ? 'sticky' : 'unset',
                    //   left: header.id === "userId" ? 0 : header.id === "id" ? header.getSize() : '',
                    //   backgroundColor: 'white',
                    //   // *****************************
                    //   width: header.getSize(),  // 列幅を反映
                    //   border: '1px solid lightgray',
                    // }}
                    // // className={header.column.getIsResizing() ? "resizer is-resizing" : "resizer"}
                    // // className={header.column.getIsPinned() + 'pinned'}
                    // draggable={!table.getState().columnSizingInfo.isResizingColumn}
                  >
                  {/* {header.column.getIsResizing().toString()}
                  {header.getSize()} */}
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    
                    
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        // 列幅を動的に変更
                        className: header.column.getCanResize() ? `resizer ${
                          header.column.getIsResizing() ? 'isResizing' : ''
                        }` : 'noResize',
                        // className: `resizer ${
                        //   header.column.getIsResizing() ? 'isResizing' : ''
                        // }`,
                      }}
                    />
                    {header.column.getCanSort() ? 
                    <button className='sortBtn' onClick={header.column.getToggleSortingHandler()}>
                    {{
                      asc: '▲',   // 昇順
                      desc: '▼',  // 降順
                    }
                      // 並び替えが行われてない場合
                      [header.column.getIsSorted() as string] ?? '▼'
                    }
                    </button> : "" }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {/* sliceで表示する行数の設定
            {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
            {table.getRowModel().rows.map((row,i) => (
              <tr key={row.id}  onClick={() => changeBackgroundColor(i)}
              className={clickedRowIndex === i ? 'ON' : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    {...{
                      key: 'Grid'+cell.id,
                      style: {
                        width: cell.column.getSize(),
                        position: cell.id.includes("_print")|| cell.id.includes("_Tatemono_Name")|| cell.id.includes("_Room_Name")|| cell.id.includes("_Address")? 'sticky' : 'unset',
                        left:cell.id.includes("_print")?"0px": (cell.id.includes("_Tatemono_Name")?"65px": (cell.id.includes("_Room_Name")?"261px": (cell.id.includes("_Address")?"311px":""))),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {/* {'*****' + cell.column.getSize() + '　'}
                    {'*****'+cell.id} */}
                    {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      {/* <h5>Page Count:{table.getPageCount()} {table.getState().pagination.pageIndex}</h5> */}
      <div className='pager'>
        <IconButton 
          // “Table API”のgetCanPreviousPageで前ページが存在するのかどうか。
          disabled={!table.getCanPreviousPage()}
          // Pagenation“Table API”のpreviousPage
          onClick={() => {
            table.setPageIndex(0);
            handleAdditionalClick();
          }}
          >
           <KeyboardDoubleArrowLeftIcon />
        </IconButton>

        <IconButton 
          disabled={!table.getCanPreviousPage()}
          onClick={() => {
            table.previousPage();
            handleAdditionalClick();
          }}
          >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {currentPage >= 1?<div className='pagenums'>…</div>:""}

        {pagesToShow.map((index) => (
          <div
            className='pagenums'
            key={index}
            style={{
              backgroundColor: table.getState().pagination.pageIndex === index ? '#3CB4FF' : '',
              color: table.getState().pagination.pageIndex === index ? '#fff' : '#000',
            }}
            onClick={() => {
              table.setPageIndex(index);
              handleAdditionalClick();
            }}
          >
            {index + 1}
          </div>
        ))}
        {pageCount > currentPage+4 ?<div className='pagenums'>…</div>:""}

        <IconButton 
          disabled={!table.getCanNextPage()}
          onClick={() => {
            table.nextPage();
            handleAdditionalClick();
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>

        <IconButton 
          disabled={!table.getCanNextPage()}
          onClick={() => {
            // table.nextPage();
            table.setPageIndex(pageCount-1);
            handleAdditionalClick();
          }}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
        
      </div>
      </main>

  ) : 
  (<></>);
}


export default App;
