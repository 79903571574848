import React, { useState, useEffect }  from 'react';
import { Route, Routes, useParams, Navigate } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import './css/login.css';


import Termsofuse from './../Termsofuse/Termsofuse';

// import Slide from './swiper';
// import Map from './Map';
import { auth } from "../../firebaseAuth";

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';


export default function Login(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  
  const [error, setError] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const [mail,setMail]=useState("");
  const [mailRules,setMailRules]=useState("");
  const [password,setPassWord]=useState("");
  const [passwordRules,setPassWordRules]=useState("");
  
  const hndMail=(x)=>{
    const regex = /.+@.+\..+/

    if(x.target.value === ""){
      setMailRules("メールアドレスを入力してください。")
    }else if(!regex.test(x.target.value)){
      setMailRules("メールアドレスの形式が違います。")
    }
    else if(regex.test(x.target.value)){
      setMailRules("")
      setMail(x.target.value)
    }
  }

  const hndPassWord=(x)=>{
    if(x.target.value === ""){
      setPassWordRules("パスワードを入力してください。")
    }else{
      setPassWordRules("")
      setPassWord(x.target.value)
    }
  }
  
  const hndLogin= async ()=>{
    var any;
    if(mail&&password){
      any={
        email:mail,
        password: password,
      }
    }
    
    console.log(any,"login_any")
    if(any){
        try {
            await signInWithEmailAndPassword(auth,any.email, any.password);
            navigate('/');
        } catch (e) {
            let jp_e_message = ''
            switch (e.code) {
                case 'auth/cancelled-popup-request':
                case 'auth/popup-closed-by-user':
                      jp_e_message = null;
                case 'auth/email-already-in-use':
                      jp_e_message = 'メールアドレスまたはパスワードが違います';
                case 'auth/invalid-email':
                    jp_e_message = 'メールアドレスの形式が正しくありません';
                case 'auth/user-disabled':
                    jp_e_message = 'サービスの利用が停止されています';
                case 'auth/user-not-found':
                    jp_e_message = 'メールアドレスまたはパスワードが違います';
                case 'auth/user-mismatch':
                      jp_e_message = 'メールアドレスまたはパスワードが違います';
                case 'auth/weak-password':
                    jp_e_message = 'パスワードは6文字以上にしてください';
                case 'auth/wrong-password':
                    jp_e_message = 'メールアドレスまたはパスワードが違います';
                case 'auth/popup-blocked':
                    jp_e_message = '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください';
                case 'auth/operation-not-supported-in-this-environment':
                case 'auth/auth-domain-config-required':
                case 'auth/operation-not-allowed':
                case 'auth/unauthorized-domain':
                    jp_e_message = '現在この認証方法はご利用頂けません';
                case 'auth/requires-recent-login':
                    jp_e_message = '認証の有効期限が切れています';
                default:
                    jp_e_message = '認証に失敗しました。しばらく時間をおいて再度お試しください';
              }
            setError(jp_e_message);
        }
    }
  }

  return (
  <main>
    <div className='login'>
      <div className='login_inner'>

        <div className='login_top'>
          <img className='logo' src={`${process.env.PUBLIC_URL}/img/global_tree.png`} alt='global_tree'/>
          <p className='site_title'>賃貸仲介業者様専用サイト</p>
        </div>

        <div className='login_body'>
          <p>メールアドレスでログインする</p>
          <div className='inputbox'>
            <TextField className='textbox' placeholder="メールアドレス" variant="outlined" 
            onChange={hndMail} error={mailRules} helperText={mailRules}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                // エンターキー押下時の処理
                hndLogin()
              }
            }}/>
          </div>
          <div className='inputbox'>
            <FormControl sx={{width: '100%' }} variant="outlined">
              <OutlinedInput placeholder="パスワード"
                id="outlined-adornment-password" type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={hndPassWord} error={passwordRules}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    hndLogin()
                  }
                }}
              />
              {passwordRules?<FormHelperText error>{passwordRules}</FormHelperText>:""}
            </FormControl>
          </div>
          {error ? <p style={{color:"#FF0000"}}>{error}</p>:<></>}
          <a href='/passReset' className='pass_forget_link'>
            <p>パスワードを忘れた場合</p>
          </a>

          <div className='TermsofuseOver'>
            <Termsofuse/>
          </div>

          <div className='btnBox'>
            <div className='loginBtn_b' onClick={hndLogin}>
              利用規約に同意してログイン
            </div>
          </div>
            
        </div>
    
      </div>

    </div>

  </main>

  );
}



