import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './css/login.css';

import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";


export default function Resetting(props) {
  
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const handleClickAuth=(num)=>{
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
        mail,
        password
      )
    reauthenticateWithCredential(user, credential).then(() => {
        setAuthError('')
        setStep(num)
    }).catch((error) => {
        console.log(error,"エラー")
    // ...
    });
  }


  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const [mail,setMail]=useState("");
  const [authError,setAuthError]=useState("");
  const [mailRules,setMailRules]=useState("");
  const [password,setPassWord]=useState("");
  const [passwordRules,setPassWordRules]=useState("");

  const [new_password,setNewPassWord]=useState("");
  const [new_passwordRules,setNewPassWordRules]=useState("");
  const [new_password2,setNewPassWord2]=useState("");
  const [new_passwordRules2,setNewPassWordRules2]=useState("");

  const [pass_umu,setPassUmu]=useState();
  
  const hndMail=(x)=>{
    const regex = /.+@.+\..+/

    if(x.target.value === ""){
      setMailRules("メールアドレスを入力してください。")
    }else if(!regex.test(x.target.value)){
      setMailRules("メールアドレスの形式が違います。")
    }
    else if(regex.test(x.target.value)){
      setMailRules("")
      setMail(x.target.value)
    }
  }

  const hndPassWord=(x)=>{
    if(x.target.value === ""){
      setPassWordRules("パスワードを入力してください。")
    }else{
      setPassWordRules("")
      setPassWord(x.target.value)
    }
  }


  const hndPassWord1=(x)=>{
    if(x.target.value === ""){
      setNewPassWordRules("パスワードを入力してください。")
    }else{
      setNewPassWordRules("")
      setNewPassWord(x.target.value)
    }
  }

  const hndPassWord2=(x)=>{
    if(x.target.value === ""){
      setNewPassWordRules2("パスワードを入力してください。")
    }else{
      setNewPassWordRules2("")
      setNewPassWord2(x.target.value)
    }
  }

  const hndPassWordSetting=()=>{
    if(new_password === new_password2){
    const auth = getAuth();
    const user = auth.currentUser;
    const passwordReset = (password) => {
        updatePassword(user, password)
        .then(() => {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        })
        .catch((err) => {
        //   setError(err.message);
        });
    };
    
    passwordReset(new_password)
    setPassUmu(false)
    }else if(new_password !== new_password2){
      setPassUmu(true)
    }
  }


  return (
  <main>
    <div className='login'>
      <div className='login_inner'>

        <div className='login_top'>
          <img className='logo' src={`${process.env.PUBLIC_URL}/img/global_tree.png`} alt='global_tree'/>
          <p className='site_title'>賃貸仲介業者様専用サイト</p>
        </div>

        {step === 0?
          <div className='login_body'>
            <p>ユーザーの認証してください</p>
            <div className='inputbox'>
              <TextField className='textbox' placeholder="メールアドレス" variant="outlined" 
              onChange={hndMail} error={mailRules} helperText={mailRules}/>
            </div>
            <div className='inputbox'>
              <FormControl sx={{width: '100%' }} variant="outlined">
                <OutlinedInput placeholder="パスワード"
                  id="outlined-adornment-password" type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={hndPassWord} error={passwordRules}
                />
                {passwordRules?<FormHelperText error>{passwordRules}</FormHelperText>:""}
              </FormControl>
            </div>
            {authError != '' ? <p className='error'>{authError}</p> : ''}
            <div className='btnBox'>
              <div className='loginBtn_b' onClick={() => handleClickAuth(2)}>
                認証
              </div>
            </div>
          </div>

        // :(step === 1?
        //   <div className='login_body'>
        //     <p>認証しました。<br/>
        //     パスワード変更を希望する方は下記より設定ください。</p>
            
        //     <div className='btnBox' style={{marginTop:"40px"}}>
        //       <div className='loginBtn_b' onClick={() => handleClick(2)}>
        //         パスワードを再設定する
        //       </div>
        //       <div className='loginBtn_h'>
        //         このまま物件一覧へ
        //       </div>
        //     </div>
        //   </div>

        :(step === 2?
          <div className='login_body'>
            <p>パスワードを変更する</p>
            <div className='inputbox'>
              <FormControl sx={{width: '100%' }} variant="outlined">
                <OutlinedInput placeholder="パスワード"
                  id="outlined-adornment-password" type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={hndPassWord1} error={new_passwordRules}
                />
                {new_passwordRules?<FormHelperText error>{new_passwordRules}</FormHelperText>:""}
              </FormControl>
            </div>

            <p style={{marginTop:"20px"}}>確認の為もう一度入力ください</p>
            <div className='inputbox'>
              <FormControl sx={{width: '100%' }} variant="outlined">
                <OutlinedInput placeholder="パスワード"
                  id="outlined-adornment-password" type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={hndPassWord2} error={new_passwordRules2}
                />
                {new_passwordRules2?<FormHelperText error>{new_passwordRules2}</FormHelperText>:""}
              </FormControl>
            </div>

            {pass_umu?<p className='error'>パスワードが一致していません。</p>:""}
            
            <div className='btnBox'>
              <div className='loginBtn_b' onClick={hndPassWordSetting}>
                設定する
              </div>
            </div>
          </div> 
        :"")
        }

       
        
    
      </div>

    </div>

  </main>

  );
}



