import React from 'react';
import { useEffect, useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, Thumbs, Pagination } from 'swiper'
// import { Navigation, Thumbs } from 'swiper'; // モジュールをインポート
// // import 'swiper/css/navigation'
// // import 'swiper/css'
// // import './style.css'
// import { Swiper, SwiperSlide } from 'swiper/react'
// Core modules imports are same as usual
import { Navigation, Thumbs } from 'swiper/modules';
// Direct React component imports
// import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
// SwiperとSwiper stylesをインポート

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation'
// import 'swiper/swiper.min.css'
// import 'swiper/modules/pagination/pagination.min.css'
// import './css/swiper.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './css/swiper.css';

export default function SampleSlider(props) {
    // console.log(props.img)
    const [thumbs, setThumbs] = useState(null)

    function getRoomImgUrl(tatemonoId, roomId, imgShubetsuNo, imgNo) {
        var num = "1";
        if (imgShubetsuNo == "8") {
            num = "0";
        }
        var reval = ''
        if(imgShubetsuNo == 2){
            reval = "https://img.global-center.co.jp/v2/tatemono/" + 
                tatemonoId + '/' + imgShubetsuNo + '/' +imgNo + "/520/780/" + num
        }else{
            // console.log(imgShubetsuNo)
            reval = "https://img.global-center.co.jp/v2/rooms/" + tatemonoId + "/" +
                roomId + "/" + imgShubetsuNo + "/" + imgNo + "/520/780/" + num
        }
        return reval;
    }


    return (
        <>
            <div className='main_swiper'>
                <Swiper
                    loop={true}
                    modules={[Navigation, Thumbs]}  
                    thumbs={{swiper: thumbs && !thumbs.destroyed ? thumbs : null}}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                >
                    {props.vr?<SwiperSlide>
                        <iframe className="vr" src={props.vr} frameborder="0" allowfullscreen=""></iframe>
                        <div className="slide_text">VR</div>
                    </SwiperSlide>:""}

                    {props.movie?<SwiperSlide>
                        <iframe class="movie" loading="lazy" src={'https://www.youtube.com/embed/' + props.movie + '?rel=0'} frameborder="0" allowfullscreen=""></iframe>
                        <div className="slide_text">動画</div>
                    </SwiperSlide>:""}

                    {/* <SwiperSlide>
                        <iframe class="movie" loading="lazy" src={'https://www.youtube.com/embed/J9OBGq262qM?rel=0'} frameborder="0" allowfullscreen=""></iframe>
                        <div className="slide_text">動画</div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <img src={'https://img.global-center.co.jp/v2/tatemono/' + props.tid +'/1/1/520/780/1'} alt="外観"/>
                        <div className="slide_text">外観</div>
                    </SwiperSlide>

                    {props.img.map((img, i) => 
                    img.Title!== "間取り図"?
                    <SwiperSlide key={'mainswiper'+i}>
                        <img src={getRoomImgUrl(props.tid,props.rid,img.Setsubi_Group_No,img.Img_Shubetsu_No)} alt={img.Title}/>
                        <div className="slide_text">{img.Title}</div>
                    </SwiperSlide>:""
                    )}

                    {props.kyouyou.map((img, i) => 
                    <SwiperSlide key={'mainkyouyouswiper'+i}>
                        <img src={getRoomImgUrl(props.tid,props.rid,img.Img_Shubetsu_No,img.Img_No)} alt={img.Title}/>
                        <div className="slide_text">{img.Title}</div>
                    </SwiperSlide>
                    )}

                    <SwiperSlide>
                        <img src={'https://img.global-center.co.jp/v2/rooms/' + props.tid +'/' + props.rid +'/8/1/520/780/0'} alt="間取り図"/>
                        <div className="slide_text">間取り図</div>
                    </SwiperSlide>
                </Swiper>
                <div className='swiper-button-next'>
                    <img src={`${process.env.PUBLIC_URL}/img/icon_slide-next.png`} alt='次へ'/>
                </div>
                <div className='swiper-button-prev'>
                    <img src={`${process.env.PUBLIC_URL}/img/icon_slide-prev.png`} alt='戻る'/>
                </div>
            </div>
            
            <div className='thumb_swiper'>
                <Swiper
                    loop={true}
                    slidesPerView={6}   
                    loopedSlides={6}
                    slidesPerGroup={6}
                    modules={[Navigation]}  
                    onSwiper={setThumbs}
                    spaceBetween={10}
                    navigation={{
                        nextEl: '.swiper-button-thumb-next',
                        prevEl: '.swiper-button-thumb-prev',
                    }}
                >
                    {props.vr?<SwiperSlide>
                        <div className='img'>
                            <img src={getRoomImgUrl(props.tid,props.rid,props.img[0].Setsubi_Group_No,props.img[0].Img_Shubetsu_No)} alt="VR"/>
                        </div>
                        <div className="slide_thumbs_text">VR</div>
                    </SwiperSlide>:""}

                    {props.movie?<SwiperSlide>
                        <div className='thum_movie img'>
                            <img src={getRoomImgUrl(props.tid,props.rid,props.img[0].Setsubi_Group_No,props.img[0].Img_Shubetsu_No)} alt="VR"/>
                            <img className='icon' src={`${process.env.PUBLIC_URL}/img/icon/icon_movie.png`} alt="icon"/>
                        </div>
                        <div className="slide_thumbs_text">動画</div>
                    </SwiperSlide>:""}

                    {/* <SwiperSlide>
                        <div className='thum_movie img'>
                            <img src={getRoomImgUrl(props.tid,props.rid,props.img[0].Setsubi_Group_No,props.img[0].Img_Shubetsu_No)} alt="VR"/>
                            <img className='icon' src={`${process.env.PUBLIC_URL}/img/icon/icon_movie.png`} alt="icon"/>
                        </div>
                        <div className="slide_thumbs_text">動画</div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className='img'>
                            <img src={'https://img.global-center.co.jp/v2/tatemono/' + props.tid +'/1/1/520/780/1'} alt="外観"/>
                        </div>
                        <div className="slide_thumbs_text">外観</div>
                    </SwiperSlide>

                    {props.img.map((img, i) => 
                    img.Title!== "間取り図"?
                    <SwiperSlide key={'thumbswiper'+i}>
                        <div className='img'>
                            <img src={getRoomImgUrl(props.tid,props.rid,img.Setsubi_Group_No,img.Img_Shubetsu_No)} alt={img.Title}/>
                        </div>
                        <div className="slide_thumbs_text">{img.Title}</div>
                    </SwiperSlide>
                    :""
                    )}

                    {props.kyouyou.map((img, i) => 
                    <SwiperSlide key={'thumbkyouyouswiper'+i}>
                        <div className='img'>
                            <img src={getRoomImgUrl(props.tid,props.rid,img.Img_Shubetsu_No,img.Img_No)} alt={img.Title}/>
                        </div>
                        <div className="slide_thumbs_text">{img.Title}</div>
                    </SwiperSlide>
                    )}

                    <SwiperSlide>
                        <div className='img'>
                        <img src={'https://img.global-center.co.jp/v2/rooms/' + props.tid +'/' + props.rid +'/8/1/520/780/0'} alt="間取り図"/>
                        </div>
                        <div className="slide_thumbs_text">間取り図</div>
                    </SwiperSlide>

                </Swiper>
                <div className='swiper-button-thumb-next'>
                    <img src={`${process.env.PUBLIC_URL}/img/icon_slide-next.png`} alt='次へ'/>
                </div>
                <div className='swiper-button-thumb-prev'>
                    <img src={`${process.env.PUBLIC_URL}/img/icon_slide-prev.png`} alt='戻る'/>
                </div>
            </div>
        </>
    )
}